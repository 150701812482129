import { inject, Injectable } from '@angular/core';
import { AbstractControl, FormArray, FormControl, FormGroup, NonNullableFormBuilder } from '@angular/forms';
import { isEqual } from 'lodash';
import { CustomValidators } from '../utils';

@Injectable({ providedIn: 'root' })
export class FormBaseService<T extends { [K in keyof T]: AbstractControl<unknown, unknown> }> {
  protected fb = inject(NonNullableFormBuilder);
  protected form!: FormGroup<T>;

  private get _rawValue(): ReturnType<typeof this.form.getRawValue> & Record<string, any> {
    return this.vm.getRawValue();
  }

  private _initialFormState?: ReturnType<typeof this.form.getRawValue> & Record<string, any> = this.vm?.getRawValue();

  get vm() {
    return this.form;
  }

  get initial() {
    return this._initialFormState;
  }


  canSubmitForm() {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return false;
    }
    return true;
  }

  checkFormDidUpdate(key: string) {
    const rawValue = this.getKeyValue(this._rawValue, key);
    const initialValue = this.getKeyValue(this._initialFormState, key);

    return !isEqual(rawValue, initialValue);
  }

  getKeyValue(obj: any, path: string) {
    return path
      .split('.')
      .reduce(
        (acc, key) => (acc && Object.prototype.hasOwnProperty.call(acc, key) ? acc[key] : undefined),
        obj
      );
  }

  resetForm() {
    this.form.reset();

    Object.keys(this.form.controls).forEach((key) => {
      if (this.form.get(key) instanceof FormArray) {
        (this.form.get(key) as FormArray).clear();
      }

      if (
        this.form.get(key) instanceof FormControl &&
        Array.isArray(this.form.get(key)?.value)
      ) {
        this.form.get(key)?.setValue([] as any);
      }
    });

    this._initialFormState = undefined;
    this.form.updateValueAndValidity();
  }

  updateFormState() {
    this._initialFormState = this.vm.getRawValue();
  }

  cleanUpPayload(removeEmpty = false) {
    let payload = this._rawValue;

    Object.keys(this._rawValue).forEach((key) => {
      if (!this.checkFormDidUpdate(key) || (removeEmpty && !payload[key])) {
        delete payload[key];
      }

      if (this.form.get(key)?.hasValidator(CustomValidators.numbers)) {
        if (payload[key]) {
          payload = {
            ...payload,
            [key]: +payload[key],
          };
        }
      }
    });

    return payload;
  }
}
