import { Injectable } from '@angular/core';
import { environment } from '../../../../../apps/main/src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ApiImagesService {
  getImageUrl(asset: string) {
    return environment.apiAssetsUrl + asset;
  }
}
