import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class AppUrlService {
  getFullUrl(path: string) {
    return window.location.origin + path;
  }
}
