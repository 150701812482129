import { Injectable, InjectionToken, Injector } from '@angular/core';
import {
  GlobalPositionStrategy,
  Overlay,
  OverlayConfig,
  OverlayRef,
} from '@angular/cdk/overlay';
import { ComponentPortal, ComponentType } from '@angular/cdk/portal';
import { asyncScheduler, Subject, take } from 'rxjs';

export interface CustomOverlayConfig {
  panelClass?: string;
  hasBackdrop?: boolean;
  backdropClass?: string;
  disableBackdropClickClose?: boolean;
  data?: unknown;
  height?: string | number;
  width?: string | number;
  position?: 'lt' | 'lc' | 'lb' | 'rt' | 'rc' | 'rb' | 'ct' | 'cb' | 'cc';
}

const DEFAULT_CONFIG: CustomOverlayConfig = {
  hasBackdrop: true,
  backdropClass: 'custom-overlay-backdrop',
  panelClass: 'custom-overlay-panel',
};

export const CUSTOM_OVERLAY_DATA = new InjectionToken<any>(
  'CUSTOM_OVERLAY_DATA',
);

@Injectable({ providedIn: 'root' })
export class OverlayService {
  private _overlayRefs: OverlayRef[] = [];

  constructor(
    private readonly _injector: Injector,
    private readonly _overlay: Overlay,
  ) {}

  open(
    config: CustomOverlayConfig,
    component: ComponentType<any>,
  ): CustomOverlayRef {
    const dialogConfig = { ...DEFAULT_CONFIG, ...config };
    const overlay = this._overlay.create(this.getOverlayConfig(dialogConfig));
    this._overlayRefs.push(overlay);
    const dialogRef = new CustomOverlayRef(overlay);
    const injector = Injector.create({
      parent: this._injector,
      providers: [
        { provide: CustomOverlayRef, useValue: dialogRef },
        { provide: CUSTOM_OVERLAY_DATA, useValue: config.data },
      ],
    });
    const portal = new ComponentPortal(component, null, injector);

    overlay.attach(portal);
    overlay
      .backdropClick()
      .pipe(take(1))
      .subscribe(() => {
        if (!config.disableBackdropClickClose) {
          overlay.dispose();
          dialogRef.backdropClickEvent.next(true);
          dialogRef.backdropClickEvent.complete();
        }
      });

    return dialogRef;
  }

  asyncOpen(config: CustomOverlayConfig, component: ComponentType<any>) {
    asyncScheduler.schedule(() => {
      this.open(config, component);
    });
  }

  closeAllOverlays() {
    this._overlayRefs.forEach((ref) => {
      ref.dispose();
    });
    this._overlayRefs = [];
  }

  private getOverlayConfig(config: CustomOverlayConfig): OverlayConfig {
    const positionStrategy = this.getPositionStrategy(config.position);

    return new OverlayConfig({
      hasBackdrop: config.hasBackdrop,
      backdropClass: config.backdropClass,
      panelClass: config.panelClass,
      height: config.height,
      width: config.width,
      scrollStrategy: this._overlay.scrollStrategies.block(),
      positionStrategy,
    });
  }

  private getPositionStrategy(
    position:
      | 'lt'
      | 'lc'
      | 'lb'
      | 'rt'
      | 'rc'
      | 'rb'
      | 'ct'
      | 'cc'
      | 'cb'
      | undefined,
  ): GlobalPositionStrategy {
    if (position === 'lt') {
      return this._overlay.position().global().left().top();
    } else if (position === 'lc') {
      return this._overlay.position().global().left().centerVertically();
    } else if (position === 'lb') {
      return this._overlay.position().global().left().bottom();
    } else if (position === 'rt') {
      return this._overlay.position().global().right().top();
    } else if (position === 'rc') {
      return this._overlay.position().global().right().centerVertically();
    } else if (position === 'rb') {
      return this._overlay.position().global().right().bottom();
    } else if (position === 'ct') {
      return this._overlay.position().global().centerHorizontally().top();
    } else if (position === 'cb') {
      return this._overlay.position().global().centerHorizontally().bottom();
    }
    return this._overlay
      .position()
      .global()
      .centerHorizontally()
      .centerVertically();
  }
}

export class CustomOverlayRef {
  public closedEvent: Subject<any> = new Subject<any>();
  public backdropClickEvent: Subject<any> = new Subject<any>();

  constructor(private overlayRef: OverlayRef) {}

  public close(data?: any): void {
    this.overlayRef.dispose();
    this.closedEvent.next(data);
    this.closedEvent.complete();
  }
}
