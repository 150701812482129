import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

const EMAIL_REGEXP =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const PHONE_NUMBER_REGEXP = /^[0-9\s\(\)\+]*$/;
const ZIPCODE_REGEXP = /^[a-zA-Z0-9\s\-]*$/;

export class CustomValidators {
  static email(AC: AbstractControl): ValidationErrors | null {
    return AC.value && !EMAIL_REGEXP.test(AC.value) ? { email: true } : null;
  }

  static phoneNumber(AC: AbstractControl): ValidationErrors | null {
    return AC.value && !PHONE_NUMBER_REGEXP.test(AC.value)
      ? { phoneNumber: true }
      : null;
  }

  static zipCode(AC: AbstractControl): ValidationErrors | null {
    return AC.value && !ZIPCODE_REGEXP.test(AC.value)
      ? { zipCode: true }
      : null;
  }

  static password(minLength = 8): ValidatorFn {
    return (AC: AbstractControl) => {
      const hasUpper = /[A-Z]/.test(AC.value);
      const hasLower = /[a-z]/.test(AC.value);
      const hasNumber = /\d/.test(AC.value);
      const hasSpecial = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(
        AC.value,
      );

      if (AC.value) {
        if (
          AC.value.length < minLength ||
          !hasUpper ||
          !hasLower ||
          !hasNumber ||
          !hasSpecial
        ) {
          return {
            password: {
              requiredLength: minLength,
              invalid: true,
            },
          };
        }
      }

      return null;
    };
  }

  static matchPassword(
    passwordKey = 'password',
    confirmPasswordKey = 'confirmPassword',
  ): ValidatorFn {
    return (AC: AbstractControl) => {
      const password = AC?.get(passwordKey);
      const confirmPassword = AC?.get(confirmPasswordKey);

      if (!password?.value && !confirmPassword?.value) return null;

      confirmPassword?.setErrors(
        password?.value !== confirmPassword?.value
          ? { matchPassword: true }
          : null,
      );

      return null;
    };
  }

  static numbers(AC: AbstractControl) {
    return AC.value && !/^[0-9]*$/.test(AC.value) ? { numbers: true } : null;
  }
}
