import { Component, Optional, Self } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';

@Component({
  selector: 'lib-input-base',
  template: '',
  host: { '(blur)': 'onTouch()' },
})
export class InputBaseComponent<T> implements ControlValueAccessor {
  protected innerValue!: T;

  disabled = false;

  constructor(@Self() @Optional() public ngControl: NgControl) {
    ngControl.valueAccessor = this;
  }

  get hasError() {
    if (this.ngControl.control?.touched && this.ngControl.control?.invalid) {
      return true;
    }
    return false;
  }

  get value() {
    return this.innerValue;
  }

  set value(value: T) {
    this.writeValue(value);
    this.onChange(value);
    this.onTouch(value);
  }

  writeValue(value: T) {
    if (value !== this.innerValue) {
      this.innerValue = value;
    }
  }

  registerOnChange(fn: () => void) {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void) {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }

  onChange: any = () => {};
  onTouch: any = () => {};
}
