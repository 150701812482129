import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { isPlatformServer } from '@angular/common';

export enum DeviceTypeEnum {
  DESKTOP,
  TABLET,
  MOBILE,
}
export interface IWindowResolutionData {
  device: DeviceTypeEnum;
  width: number;
}

@Injectable({
  providedIn: 'root',
})
export class WindowResizeService {
  windowResolutionData$ = new BehaviorSubject<IWindowResolutionData>({
    device: this._getDeviceType(this._getInitialValue()),
    width: this._getInitialValue(),
  });

  constructor(@Inject(PLATFORM_ID) private readonly _platformId: Object) {
    this._setupResizeListener();
  }

  private _getInitialValue() {
    if (isPlatformServer(this._platformId)) return 0;

    return window.innerWidth;
  }

  private _setupResizeListener() {
    if (isPlatformServer(this._platformId)) return;

    window.addEventListener('resize', () => {
      const newWidth = window.innerWidth;

      if (newWidth === this.windowResolutionData$.value.width) return;

      this.windowResolutionData$.next({
        device: this._getDeviceType(newWidth),
        width: newWidth,
      });
    });
  }

  private _getDeviceType(width: number): DeviceTypeEnum {
    if (width <= 768) {
      return DeviceTypeEnum.MOBILE;
    } else if (width <= 1024) {
      return DeviceTypeEnum.TABLET;
    } else {
      return DeviceTypeEnum.DESKTOP;
    }
  }
}
