import { inject, Injectable, PLATFORM_ID } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { isPlatformServer } from '@angular/common';
import { BehaviorSubject } from 'rxjs';
import { APILanguage, Language, LanguageCode, LocaleCode } from '../types';

@Injectable({
  providedIn: 'root',
})
export class TranslationService {
  private readonly _translateService = inject(TranslateService);
  private readonly _platformId = inject(PLATFORM_ID);

  languageChangeEmitter$ = new BehaviorSubject<Language>(
    this.getCurrentLanguage(),
  );

  setInitialLanguage() {
    if (isPlatformServer(this._platformId)) return;

    const userLanguage = window.localStorage.getItem('surfRecLanguage');

    this.setLanguage((userLanguage ?? 'en') as LanguageCode);
  }

  setLanguage(lang: LanguageCode, changeWithReload = false) {
    if (!changeWithReload) {
      this._translateService.use(lang);

      this.languageChangeEmitter$.next({
        code: lang,
        locale: this._prepareCurrentLocalize(lang),
      });
    }

    if (isPlatformServer(this._platformId)) return;

    window.localStorage.setItem('surfRecLanguage', lang);

    changeWithReload && location.reload();
  }

  getCurrentLanguage() {
    const langCode = (this._translateService.currentLang ??
      'en') as LanguageCode;

    return {
      code: langCode,
      locale: this._prepareCurrentLocalize(langCode),
    };
  }

  getApiLanguage(lang?: Language): APILanguage {
    switch (lang?.code ?? this.getCurrentLanguage().code) {
      case 'pt':
        return 'PT';
      case 'es':
        return 'ES';
      default:
        return 'ENG';
    }
  }

  private _prepareCurrentLocalize(langCode: LanguageCode): LocaleCode {
    switch (langCode) {
      case 'pt':
        return 'pt-PT';
      case 'es':
        return 'es-ES';
      default:
        return 'en-US';
    }
  }
}
