import { inject, Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root',
})
export class SeoService {
  private readonly _titleService = inject(Title);
  private readonly _metaService = inject(Meta);

  updateSEO(pageTitle: string, metaContent: string) {
    this._updateTitle(pageTitle);
    this._updateMeta('description', metaContent);
  }

  private _updateTitle(title: string) {
    this._titleService.setTitle(title);
  }

  private _updateMeta(name: string, content: string) {
    this._metaService.updateTag({ name, content });
  }
}
